export class JSONUtils {
  private static ignoreProperty(blackListedProperties: (number | string)[], key: string, value: any): any {
    return blackListedProperties.indexOf(key) === -1 ? value : undefined;
  }

  static clone<T>(object: T): T;
  static clone(object: any, whiteListedProperties: (number | string)[]): any;
  static clone(object: any, whiteListedProperties?: (number | string)[]): any {
    return JSON.parse(JSON.stringify(object, whiteListedProperties));
  }

  static cloneWithBlackList(object: any, blackListedProperties: (number | string)[]): any {
    const boundIgnoreProperty = JSONUtils.ignoreProperty.bind(null, blackListedProperties);
    return JSON.parse(JSON.stringify(object, boundIgnoreProperty));
  }

  static stringifyWithBlacklist(object: any, blackListedProperties: (number | string)[]): string {
    const boundIgnoreProperty = JSONUtils.ignoreProperty.bind(null, blackListedProperties);
    return JSON.stringify(object, boundIgnoreProperty);
  }
}
