<div [class.has-icon]="!!icon">
  @if (!!icon) {
    <div class="message-icon">
      <mat-icon [innerHTML]="icon"></mat-icon>
    </div>
  }
  <div class="message-content">
    <ng-content></ng-content>
  </div>
</div>
